.gallery_slide_bg {
    background: rgb(73, 50, 107);
    background: linear-gradient(
        90deg,
        rgba(73, 50, 107, 1) 0%,
        rgba(85, 43, 111, 0.7880091292134832) 30%,
        rgba(126, 0, 106, 0) 100%
    );
}

/* GalleryCard.css (create a separate CSS file and import it in the component) */

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 0;
}

.gallery-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
/* 
.gallery-card img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
}

.card-info {
    padding: 1rem;
}

.category {
    color: #999;
    font-size: 0.8rem;
    text-transform: uppercase;
}

.title {
    margin: 0.5rem 0;
    font-size: 1.1rem;
}

.author {
    color: #888;
    font-size: 0.9rem;
}

.price {
    color: #007bff;
    font-size: 1.2rem;
    font-weight: bold;
} */
