.blog_subscribe-bg {
    background: rgb(73, 50, 107);
    background: linear-gradient(
        90deg,
        rgba(73, 50, 107, 1) 0%,
        rgba(120, 0, 145, 1) 49%,
        rgba(73, 50, 107, 1) 100%
    );
}

.blog_category-bg {
    background: rgb(73, 50, 107);
    background: linear-gradient(
        90deg,
        rgba(73, 50, 107, 1) 0%,
        rgba(161, 0, 135, 0.9902563202247191) 98%
    );
}

/* Add Container */

.ad-container {
    width: 100%;
    height: auto;
    margin: 10px;
    display: flex;
    justify-content: center;
}

.ad-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
}
