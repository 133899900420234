@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style-type: none;
    text-decoration: none;
    outline: none;
}

.navbar {
    position: fixed;
    top: 40px;
    left: 40px;
    right: 40px;
    backdrop-filter: blur(4px);
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.5);
    transition: 0.3s ease-in;
}

.stick {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* backdrop-filter: blur(4px); */
    z-index: 999;
    /* background-color: rgba(255, 255, 255, 0.5); */
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-out;
    border-radius: 0 !important;
}
.blog {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.aboutbg {
    background: url("./assets/about.png") center center no-repeat;
    background-size: cover;
    height: calc(100vh - 67px);
}

/* Service swiper */
.abcd
    .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 5% !important;
}

/* leftArrowIcon For Dashboard */

.leftArrowIcon::after {
    position: absolute;
    right: 0;
    content: "";
    border-right: 22px solid #49326b;
    border-top: 22px solid transparent;
    border-bottom: 22px solid transparent;
}
